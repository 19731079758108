
import { Component, Prop, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import type Day from '@/modules/common/types/day.type';
import Calendar from '@/modules/common/components/ui-kit/calendar.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import FleetCalendarItem from '@/modules/cars/modules/fleet/components/calendar/fleet-calendar-item.vue';
import FleetCalendarItemNoData from '@/modules/cars/modules/fleet/components/calendar/fleet-calendar-item-no-data.vue';
import FleetCalendarItemOutRange from '@/modules/cars/modules/fleet/components/calendar/fleet-calendar-item-out-range.vue';
import FleetCalendarItemSoldOut from '@/modules/cars/modules/fleet/components/calendar/fleet-calendar-item-sold-out.vue';
import FleetCalendarItemEmpty from '@/modules/cars/modules/fleet/components/calendar/fleet-calendat-item-empty.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import type { IResponsiveStyle } from '@/modules/cars/modules/fleet/fleet-settings.types';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';

@Component({
    components: {
        Calendar,
        FleetCalendarItem,
        FleetCalendarItemOutRange,
        FleetCalendarItemSoldOut,
        FleetCalendarItemEmpty,
        FleetCalendarItemNoData,
        LoaderWrapper,
    },
})
export default class FleetCalendar extends Vue {
    @inject(FleetServiceS) fleetService!: FleetService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;

    @Prop({
        required: true,
        type: String,
    })
    source!: string;

    mounted() {
        this.eventsManagerService.saveIsLoadEventByPOS(true);
    }

    beforeDestroy() {
        this.eventsManagerService.saveIsLoadEventByPOS(false);
    }

    get year() {
        return this.documentFiltersService.storeState.settings.year;
    }

    get month() {
        return this.documentFiltersService.storeState.settings.month;
    }

    get isExistSource(): boolean {
        return this.fleetService.isExistSource(this.source);
    }

    outOfRange(day: Day) {
        return this.fleetService.isOutOfRange(day, this.source);
    }

    isSoldOut(day: Day) {
        return this.fleetService.isSoldOut(day, this.source);
    }

    isNA(day: Day) {
        return this.fleetService.isNa(day, this.source) === 0;
    }

    get countSources() {
        return this.fleetService.dataSources.length;
    }

    get itemStyles(): IResponsiveStyle {
        if (this.countSources === 1) {
            return {
                heightBlock: '95px',
                positionSize: '20px',
                daySize: '15px',
            };
        }

        if (this.countSources > 1 && this.countSources < 3) {
            return {
                heightBlock: '65px',
                positionSize: '12px',
                daySize: '7.5px',
            };
        }

        return {
            heightBlock: '50px',
            positionSize: '12px',
            daySize: '7.5px',
        };
    }
}
