
import { inject } from '@/inversify';
import { Component, Vue } from 'vue-property-decorator';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import FleetGraphBrokers from '@/modules/cars/modules/fleet/components/graph/fleet-graph-brokers.vue';
import FleetGraphRangeLabel from '@/modules/cars/modules/fleet/components/graph/fleet-graph-range-label.vue';
import GRAPH_TYPE from '@/modules/cars/modules/fleet/constants/graph-types.constant';
import FleetGraphBrokersLabel from '@/modules/cars/modules/fleet/components/graph/fleet-graph-brokers-label.vue';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import FleetGraphHeader from './fleet-graph-header.vue';
import FleetGraphRange from './fleet-graph-range.vue';

@Component({
    components: {
        FleetGraphBrokersLabel,
        FleetGraphRange,
        FleetGraphBrokers,
        FleetGraphHeader,
        FleetGraphRangeLabel,
    },
})
export default class FleetGraphSet extends Vue {
    @inject(FleetServiceS) private fleetService!: FleetService;
    @inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;

    get providers() {
        return this.fleetService.brokers;
    }

    mounted() {
        this.eventsManagerService.saveIsLoadEventByPOS(true);
    }

    beforeDestroy() {
        this.eventsManagerService.saveIsLoadEventByPOS(false);
    }

    isRange(provider: string) : boolean {
        return this.fleetService.getGraphType(provider) === GRAPH_TYPE.RANGE;
    }
}
