var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container"},[_c('span',{class:{
            'text before': true,
            'active': !_vm.value
        }},[_c('span',[_vm._v(_vm._s(_vm.inactiveText))])]),_c('label',{class:{
            'switch': true,
            ['switch--' + _vm.appearance]: !!_vm.appearance,
        }},[_c('input',{attrs:{"type":"checkbox","disabled":_vm.isDisabled},domProps:{"checked":_vm.value},on:{"input":function($event){return _vm.handleChange($event.target.checked)}}}),_c('span',{staticClass:"slider round",class:{ 'disabled': _vm.isDisabled }})]),_c('span',{class:{
            'text after': true,
            'active': _vm.value
        }},[_c('span',[_vm._v(_vm._s(_vm.activeText))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }