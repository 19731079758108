
import { Component, Prop, Vue } from 'vue-property-decorator';

const RANGES = [
    { min: 0, max: 20, title: 'Very Low' },
    { min: 21, max: 40, title: 'Low' },
    { min: 41, max: 60, title: 'Medium' },
    { min: 61, max: 80, title: 'High' },
    { min: 81, max: 100, title: 'Very High' },
];

/**
 * @deprecated
 * Old version of Demand component. Still is used only in Cars. If you need demand components, consider to use ui-kit/demand/index.vue instead.
 */
@Component
export default class Demand extends Vue {
    @Prop({
        type: Number,
        validator(value: any): boolean {
            return value >= 0 && value <= 100;
        },
    })
    public demandNumber?: number;

    @Prop({
        type: Boolean,
        default: false,
    })
    public isHorizontal!: boolean;

    @Prop({
        type: String,
        default: 'left',
    })
    public tooltipSide!: string;

    @Prop({
        type: String,
        default: null,
    })
    title!: string | null;

    public ranges = RANGES;
    private readonly DEFAULT_DEMAND = 30;

    public get demandLevel(): number {
        const demand = this.demandNumber !== undefined ? this.demandNumber : this.DEFAULT_DEMAND;
        return this.getDemandLevel(demand);
    }

    get tooltipText() {
        if (this.demandNumber === undefined) {
            return undefined;
        }
        return this.title ? this.title : `${this.$tc('titles.marketDemand')} ${Math.round(this.demandNumber)}%`;
    }

    getDemandRange(demand: number): typeof RANGES[0] | undefined {
        const validRanges = RANGES.filter(range => {
            const isOverRange = demand >= range.max;
            const isInRange = demand >= range.min && demand <= range.max;

            return isOverRange || isInRange;
        });

        return validRanges.pop();
    }

    getDemandLevel(demand: number): number {
        const range = this.getDemandRange(demand);

        if (range) {
            return RANGES.indexOf(range) + 1;
        }

        return 0;
    }
}
