
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ScanBtnSmall extends Vue {
    @Prop({
        type: Function,
    })
    onClick!: (e?: MouseEvent) => void;

    @Prop({
        type: Boolean,
        default: false,
    })
    isScanning!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    disabled!: boolean;

    handleClick(e?: MouseEvent) {
        if (this.onClick) {
            this.onClick(e);
        }

        this.$emit('click', e);
    }
}
