
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import CustomMultiSelect, { Item } from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import FleetFilterService, { FleetFilterServiceS } from '@/modules/cars/modules/fleet/fleet-filter.service';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';

@Component({
    components: { CustomMultiSelect },
})
export default class CompetitorsFilter extends Vue {
    @inject(FleetFilterServiceS) private fleetFiltersService!: FleetFilterService;
    @inject(FleetServiceS) private fleetService!: FleetService;

    get currentValue() {
        const { competitors } = this.fleetService.storeState.settings;
        const { competitorsFilter } = this.fleetFiltersService;

        if (!competitors) {
            return [];
        }

        return competitors.filter(item => competitorsFilter.includes(item)).map(item => ({
            name: item,
            value: item,
        })) || [];
    }
    set currentValue(value) {
        this.fleetService.storeState.settings.competitors = value.map(item => item.value);
        this.fleetFiltersService.saveCompetitors(value.map(item => item.value));
    }

    get options() {
        return this.fleetFiltersService.competitorsFilter.map(val => ({
            value: val,
            name: val,
        })).sort((a, b) => {
            if (a.value < b.value) {
                return -1;
            }
            return 1;
        });
    }
}
