
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import SCAN_STATUS from '@/modules/rates/constants/scan-status.constant';
import type Day from '@/modules/common/types/day.type';
import ScanBtnSmall from '@/modules/common/components/ui-kit/scan-btn-small.vue';

@Component({
    components: {
        ScanBtnSmall,
    },
})
export default class FleetDayScanBtn extends Vue {
    @inject(FleetServiceS) fleetService!: FleetService;

    @Prop({
        type: Number,
        required: true,
    })
    private day!: Day | undefined;

    get isScanning() {
        return false;
    }

    get disabled() {
        return true;
    }

    async handleClick() {
        if (this.isScanning || this.disabled) {
            return;
        }
        await this.fleetService.triggerScan(this.day);
    }

    get formatScanDate() {
        if (!this.fleetService.finishScanDate) {
            return '--/--/--';
        }

        const currentDate = new Date();

        const date = this.fleetService.finishScanDate.getDate();
        const month = this.fleetService.finishScanDate.getMonth();
        const year = this.fleetService.finishScanDate.getFullYear();

        const todayDate = currentDate.getDate();
        const todayMonth = currentDate.getMonth();
        const todayYear = currentDate.getFullYear();

        const lastDateSum = date + month + year;
        const todaySum = todayDate + todayMonth + todayYear;

        let todayOrYesterday = '';
        if (lastDateSum - todaySum === -1) {
            todayOrYesterday = 'yesterday';
        } else if (lastDateSum - todaySum === 0) {
            todayOrYesterday = 'today';
        }

        if (todayOrYesterday) {
            return `${todayOrYesterday}, ${new Intl.DateTimeFormat('default', {
                hour12: true,
                hour: 'numeric',
                minute: 'numeric',
            }).format(this.fleetService.finishScanDate)}`;
        }

        return `${date}/${month + 1}/${year}`;
    }
}
