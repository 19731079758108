
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class HorizontalArrows extends Vue {
    emitPref() {
        this.$emit('prev-click');
    }

    emitNext() {
        this.$emit('next-click');
    }
}
