
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import PROVIDER_COLORS from '@/modules/common/constants/providers.colors.constant';

@Component
export default class FleetGraphRangeLabel extends Vue {
    @inject(HotelsServiceS) protected hotelsService!: HotelsService;
    @inject(UserServiceS) protected userService!: UserService;

    get currentCompany() {
        return this.userService.currentCompany;
    }

    get companyId() {
        return Math.random();
    }

    get getIconColor() {
        const color = this.userService.currentCompany ? PROVIDER_COLORS[this.userService.currentCompany] : '#555555';
        return `border: 3px solid ${color}`;
    }
}
