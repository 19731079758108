
import { Prop, Vue, Component } from 'vue-property-decorator';
import Flag from '@/modules/common/components/ui-kit/flag.vue';
import EventTypeIcon from '@/modules/common/components/ui-kit/event-type-icon.vue';

export interface Item {
    country: string,
    countryCode: string,
    name: string,
    description: string,
}

@Component({
    components: { Flag, EventTypeIcon },
})
export default class CalendarEvents extends Vue {
    @Prop({
        required: true,
        type: Array as () => Item[],
    })
    eventsData!: Item[];

    @Prop({
        required: true,
        type: Boolean,
    })
    localEvents!: boolean;

    maxEventCount = 3;

    get slicedEventsData() {
        return this.eventsData.slice(0, this.maxEventCount);
    }
}
