
import { Component, Prop, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import ScanBtn from '@/modules/common/components/ui-kit/scan-btn.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';

@Component({ components: { ScanBtn } })
export default class FleetScan extends Vue {
    @inject(FleetServiceS) private marketsService!: FleetService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;

    @Prop({
        type: String,
    })
    private provider!: string;

    @Prop({
        type: Number,
    })
    private id!: number;

    get isScanning() {
        return false;
    }

    get disabled() {
        return false;
    }

    get lastScanDate() {
        return new Date();
    }

    get title() {
        return this.disabled && this.documentFiltersService.isPreviousMonth ? 'Not allowed to scan previous months' : '';
    }

    async handleClick() {
        return '';
    }
}
