import { render, staticRenderFns } from "./competitors-filter.vue?vue&type=template&id=1f5be7f6&scoped=true&"
import script from "./competitors-filter.vue?vue&type=script&lang=ts&"
export * from "./competitors-filter.vue?vue&type=script&lang=ts&"
import style0 from "./competitors-filter.vue?vue&type=style&index=0&id=1f5be7f6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f5be7f6",
  null
  
)

export default component.exports