import Percent from '@/modules/common/types/percent.type';

export default function PercentFilter(value: Percent, noSign = false) {
    let currentValue = value ? Number(value.toFixed(2)) : 0;
    if (currentValue === 0) {
        currentValue = 0; // if currentValue = -0 convert it to 0
    }

    const sign = (currentValue > 0) ? '+' : '';
    let output = (currentValue * 100).toLocaleString('en-US', { maximumFractionDigits: 0 });

    if (!noSign) {
        output = `${sign}${output}`;
    }

    return output;
}
