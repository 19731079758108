
import 'reflect-metadata';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { DatePicker } from 'element-ui';
import VueI18n from 'vue-i18n';

const onlyValues = (values: any) => (str: string) => values.indexOf(str) !== -1;

@Component({
    components: {
        'el-date-picker': DatePicker,
    },
})

export default class MonthPicker extends Vue {
    @Prop({
        required: true,
        type: Date,
    })
    value?: Date;

    @Prop({
        required: true,
        type: String,
    })
    locale?: VueI18n.Locale;

    get date() {
        return this.value!;
    }

    set date(value: Date) {
        this.$emit('input', value);
    }
}
