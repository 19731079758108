
import { Component, Prop, Vue } from 'vue-property-decorator';
import FleetGraphTypeChanger from '@/modules/cars/modules/fleet/components/graph/fleet-graph-type-changer.vue';
import ProviderCard from '@/modules/common/components/ui-kit/provider-card.vue';

@Component({
    components: {
        ProviderCard,
        FleetGraphTypeChanger,
    },
})
export default class FleetGraphHeader extends Vue {
    @Prop({
        type: String,
        required: true,
    })
    provider!: string;
}
