
import { Component, Vue } from 'vue-property-decorator';
import PickUpCityFilter from '@/modules/cars/modules/fleet/components/actions/pick-up-city-filter.vue';
import DataSourceFilter from '@/modules/cars/modules/fleet/components/actions/data-source-filter.vue';
import LorFilter from '@/modules/cars/modules/fleet/components/actions/lor-filter.vue';
import PosFilter from '@/modules/cars/modules/fleet/components/actions/pos-filter.vue';
import PriceTypeFilter from '@/modules/cars/modules/fleet/components/actions/price-type-filter.vue';
import TransmissionFilter from '@/modules/cars/modules/fleet/components/actions/transmission-filter.vue';
import MileageFilter from '@/modules/cars/modules/fleet/components/actions/mileage-filter.vue';
import CompetitorsFilter from '@/modules/cars/modules/fleet/components/actions/competitors-filter.vue';
import PaymentTermsFilter from '@/modules/cars/modules/fleet/components/actions/payment-terms-filter.vue';
import CountryFilter from '@/modules/cars/modules/fleet/components/actions/country-filter.vue';
import CarClassesFilter from '@/modules/cars/modules/fleet/components/actions/car-classes-filter.vue';

@Component({
    components: {
        PickUpCityFilter,
        DataSourceFilter,
        LorFilter,
        PosFilter,
        PriceTypeFilter,
        TransmissionFilter,
        MileageFilter,
        CompetitorsFilter,
        PaymentTermsFilter,
        CountryFilter,
        CarClassesFilter,
    },
})
export default class FleetActions extends Vue {
    get currentPath() {
        return this.$route.path;
    }
}
