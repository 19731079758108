
import EventsModel from '@/modules/events/models/events.model';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import CalendarEvents from '@/modules/common/components/ui-kit/calendar-events.vue';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';

@Component({
    components: { CalendarEvents },
})
export default class CalendarEventsContainer extends Vue {
    @inject(EventsManagerServiceS)
    private eventsManagerService!: EventsManagerService;

    @inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Prop({ required: true })
    private day!: number;

    @Prop({
        type: String,
        default: '',
    })
    public emptyText!: string;

    get hotelId() {
        return +this.$route.params.hotelId;
    }

    get localEvents() {
        const { year, month } = this.documentFiltersService;
        const { day } = this;
        const date = new Date(Date.UTC(year, month, day, 0, 0, 0, 0));

        return this.eventsManagerService.hasDateOtherEvents(date, this.hotelId);
    }

    get eventsData(): EventsModel[] {
        const { year, month } = this.documentFiltersService;
        const { day } = this;
        const date = new Date(Date.UTC(year, month, day, 0, 0, 0, 0));

        return this.eventsManagerService.getHolidayEvents(date);
    }
}
