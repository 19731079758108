
import { Component, Prop, Vue } from 'vue-property-decorator';
import EVENT_TYPE_SETTINGS from '@/modules/events/constants/event-types-settings.constant';

@Component
export default class EventTypeIcon extends Vue {
    @Prop({
        required: true,
        type: String,
    })
    private type?: EVENT_TYPE_SETTINGS;

    get icon() : string {
        switch (this.type) {
            case EVENT_TYPE_SETTINGS.COMMUNITY:
                return 'icon-CI-Community';
            case EVENT_TYPE_SETTINGS.CONCERTS:
                return 'icon-CI-musical';
            case EVENT_TYPE_SETTINGS.CONFERENCES:
                return 'icon-CI-Confrences';
            case EVENT_TYPE_SETTINGS.EXPOS:
                return 'icon-CI-Expo';
            case EVENT_TYPE_SETTINGS.FESTIVAL:
                return 'icon-CI-Festival';
            case EVENT_TYPE_SETTINGS.PERFORMING_ARTS:
                return 'icon-CI-Show';
            case EVENT_TYPE_SETTINGS.POLITICS:
                return 'icon-CI-Flag';
            case EVENT_TYPE_SETTINGS.SPORTS:
                return 'icon-CI--bicycle';
            case EVENT_TYPE_SETTINGS.OTHER:
                return 'icon-CI-Politics';
            default: return 'icon-CI-Politics';
        }
    }
}
