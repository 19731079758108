
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class EventTypePoint extends Vue {
    @Prop({ required: false })
    private isHoliday!: Boolean;

    @Prop({ required: false })
    private isLocal!: Boolean;
}
