
import moment from 'moment';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';

import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';

import SCAN_STATUS from '@/modules/rates/constants/scan-status.constant';
import type Day from '@/modules/common/types/day.type';
import ScanBtnSmall from '@/modules/common/components/ui-kit/scan-btn-small.vue';

import RatesScanTooltip from './rates-scans-tooltip.vue';

@Component({
    components: {
        ScanBtnSmall,
        RatesScanTooltip,
    },
})
export default class RatesDayScanBtn extends Vue {
    @inject(RatesServiceS) private ratesService!: RatesService;
    @inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;

    @Prop({ type: Number, required: true })
    private day!: Day | undefined;

    tooltipPos: HTMLDivElement | null = null;

    get isScanning() {
        return this.ratesService.scanStatus === SCAN_STATUS.IN_PROGRESS;
    }

    get disabled() {
        const { scanLimitation } = this.ratesCommonService;
        const haveNoScans = scanLimitation && scanLimitation.remaining === 0;

        return !this.ratesService.isScanAvailable(this.day) || haveNoScans;
    }

    get hotelId() {
        return +this.$route.params.hotelId;
    }

    async handleClick() {
        if (this.isScanning || this.disabled) {
            return;
        }
        await this.ratesService.triggerScan(this.day);
    }

    get formatScanDate() {
        const dateScan = this.ratesService.getUpdateDate(this.day!);

        if (!dateScan) {
            return '--/--/--';
        }

        const currentDate = new Date();

        const date = dateScan.getDate();
        const month = dateScan.getMonth();
        const year = dateScan.getFullYear();

        const todayDate = currentDate.getDate();
        const todayMonth = currentDate.getMonth();
        const todayYear = currentDate.getFullYear();

        const lastDateSum = date + month + year;
        const todaySum = todayDate + todayMonth + todayYear;

        let todayOrYesterday = '';
        if (lastDateSum - todaySum === -1) {
            todayOrYesterday = this.$tc('titles.yesterday');
        } else if (lastDateSum - todaySum === 0) {
            todayOrYesterday = this.$tc('titles.today');
        }

        if (todayOrYesterday) {
            return `${todayOrYesterday} ${moment(dateScan).format('LT')}`;
        }

        return `${date}/${month + 1}/${year}`;
    }
}
