
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import FleetFilterService, { FleetFilterServiceS } from '@/modules/cars/modules/fleet/fleet-filter.service';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

@Component({
    components: { CustomSelect },
})
export default class LorFilter extends Vue {
    @inject(FleetFilterServiceS) private fleetFiltersService!: FleetFilterService;
    @inject(FleetServiceS) private fleetService!: FleetService;
    @inject(UserServiceS) private userService!: UserService;

    get items(): Item[] {
        let { availableLors } = this.fleetFiltersService;

        if (!availableLors) {
            return [];
        }

        if (!this.currentValue || !availableLors.find(lor => Number(lor) === Number(this.currentValue))) {
            this.currentValue = Number(availableLors[0]);
        }

        if (this.userService && this.userService.user && this.userService.user.chainName === 'Rentalcars') {
            const lorsToInclude = ['3', '7', '10', '14'];
            availableLors = availableLors.filter(item => lorsToInclude.includes(item));
        } else if (this.userService && this.userService.user && this.userService.user.chainName === 'Hertz') {
            const lorsToInclude = ['1', '2', '3', '4', '5', '7', '28'];
            availableLors = availableLors.filter(item => lorsToInclude.includes(item));
        } else if (this.userService && this.userService.user && this.userService.user.chainName === 'Lyft') {
            const lorsToInclude = ['1', '2', '5', '7', '14', '28'];
            availableLors = availableLors.filter(item => lorsToInclude.includes(item));
        }

        return availableLors.map(value => ({
            name: String(value),
            value,
        }));
    }

    get currentValue() {
        return this.fleetService.storeState.settings.lor;
    }
    set currentValue(value) {
        if (value) {
            this.fleetFiltersService.saveLor(value);
        }
    }
}
