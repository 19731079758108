
import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
// @ts-ignore
import CustomDropdown, { IDropdownItem } from '@/modules/common/components/ui-kit/custom-dropdown.vue';
import type Day from '@/modules/common/types/day.type';

@Component({
    components: {
        CustomDropdown,
    },
})
export default class CarsDotDropdown extends Vue {
    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    @Prop({
        required: true,
        type: Boolean,
    })
    private refresh!: boolean;

    @Prop({
        required: false,
        type: Array,
    })
    private menuItems!: IDropdownItem[];

    show: boolean = true;
    isOpenDropdown: boolean = false;

    @Watch('refresh')
    closeMenu() {
        if (this.isOpenDropdown) {
            this.show = false;
            Vue.nextTick(() => {
                this.show = true;
            });
        }
    }

    handleToggleDropdown(flag: boolean) {
        this.isOpenDropdown = flag;
        this.$emit('toggle-menu', flag);
    }

    items(day: Day = 1): IDropdownItem[] {
        if (this.menuItems) {
            return this.menuItems;
        }
        return [
            {
                link: `day-popup/${day}`,
                text: 'Details',
            },
            // {
            //     link: `price-history/${day}`,
            //     text: 'Price History',
            // },
        ];
    }
}
