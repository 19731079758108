
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import FleetFilterService, { FleetFilterServiceS } from '@/modules/cars/modules/fleet/fleet-filter.service';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';

@Component({
    components: { CustomSelect },
})
export default class TransmissionFilter extends Vue {
    @inject(FleetFilterServiceS) private fleetFiltersService!: FleetFilterService;
    @inject(FleetServiceS) private fleetService!: FleetService;

    get items(): Item[] {
        const { transmissionFilter } = this.fleetFiltersService;
        return transmissionFilter.map(({ value, disabled }) => ({
            name: String(value),
            value,
            disabled,
        }));
    }

    get currentValue() {
        return this.fleetService.storeState.settings.transmission;
    }
    set currentValue(value) {
        if (value) {
            this.fleetFiltersService.saveTransmisson(value);
        }
    }
}
