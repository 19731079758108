
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import formatDate from '../../filters/format-date.filter';

@Component
export default class ScanBtn extends Vue {
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;

    @Prop({ type: Function })
    onClick!: (e?: MouseEvent) => void;

    @Prop({ type: [Date, String], default: null })
    lastScanDate!: Date | string | null;

    @Prop({ type: Boolean, default: false })
    isScanning!: boolean;

    @Prop({ type: Object, default: null })
    progress!: {
        percentDone: number;
        secondsLeft: number;
    } | null;

    @Prop({ type: Boolean, default: false })
    disabled!: boolean;

    handleClick(e: MouseEvent) {
        if (this.isScanning) {
            return;
        }

        if (this.onClick) {
            this.onClick(e);
        }

        this.$emit('click', e);
    }

    get formatDate() {
        return formatDate(this.lastScanDate);
    }

    get isPrevMonth() {
        const { month, year } = this.documentFiltersService.storeState.settings;
        const today = new Date();
        const todayYear = today.getFullYear();
        const todayMonth = today.getMonth();

        if (todayYear > year || (todayYear === year && todayMonth > month)) {
            return true;
        }

        return false;
    }

    get progressMsgKey() {
        const messages = [
            'rates.demand.5mins',
            'rates.demand.10mins',
            'rates.demand.15mins',
            'rates.demand.20mins',
            'rates.demand.toomuch',
            'rates.demand.unknown',
        ];

        if (!this.progress) {
            return messages[5];
        }

        if (this.progress.secondsLeft < 300) {
            return messages[0];
        }

        if (this.progress.secondsLeft < 600) {
            return messages[1];
        }

        if (this.progress.secondsLeft < 900) {
            return messages[2];
        }

        if (this.progress.secondsLeft < 1200) {
            return messages[3];
        }

        return messages[4];
    }

    get progressSvgOffset() {
        const startOffset = -51;
        if (!this.progress) {
            return startOffset;
        }
        const percentDone = this.progress.percentDone <= 100 ? this.progress.percentDone : 100;
        return Math.round(startOffset - (-startOffset / 100 * percentDone));
    }
}
