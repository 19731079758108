
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import FleetFilterService, { FleetFilterServiceS } from '@/modules/cars/modules/fleet/fleet-filter.service';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';

@Component({
    components: { CustomMultiSelect },
})
export default class CarClassesFilter extends Vue {
    @inject(FleetFilterServiceS) private fleetFiltersService!: FleetFilterService;
    @inject(FleetServiceS) private fleetService!: FleetService;

    get items() {
        return this.fleetFiltersService.carClassFilter.map(val => ({
            value: val,
            name: val,
        })).sort((a, b) => {
            if (a.value < b.value) {
                return -1;
            }
            return 1;
        });
    }

    get currentValue() {
        if (!this.fleetService.storeState.settings.carClasses) {
            return null;
        }

        return this.fleetService.storeState.settings.carClasses
            .filter(val => this.fleetFiltersService.carClassFilter.includes(val))
            .map(val => ({
                value: val,
                name: val,
            })) || [];
    }
    set currentValue(value) {
        if (value) {
            this.fleetFiltersService.saveCarClasses(value.map(item => item.value));
        }
    }
}
