
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import FleetFilterService, { FleetFilterServiceS } from '@/modules/cars/modules/fleet/fleet-filter.service';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';

@Component({
    components: { CustomSelect },
})
export default class CountryFilter extends Vue {
    @inject(FleetFilterServiceS) private fleetFiltersService!: FleetFilterService;
    @inject(FleetServiceS) private fleetService!: FleetService;
    @inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    get items(): Item[] {
        const { countriesFilter } = this.fleetFiltersService;

        if (!countriesFilter) {
            return [];
        }

        return countriesFilter.filter(country => this.fleetFiltersService.filterPickUpCitiesByCountry(country).length > 0)
            .map(value => ({
                name: String(value),
                value,
            }));
    }

    get currentValue() {
        return this.fleetService.storeState.settings.country;
    }
    set currentValue(value) {
        if (value) {
            this.carsSharedService.saveCountry(value);
            this.carsSharedService.saveCluster(null);
        }
    }
}
